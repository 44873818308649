.header{
    display: flex;
    flex: 1;
    width: 100vw;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 19px 60px rgb(0 0 0 / 18%);
    background: rgb(251, 253, 252);
    padding: 10px 35px;
    position: fixed;
    z-index: 99;
    top: 0;
    

}
.logo{
    width: 7rem;
    height: 7rem;
  
}

.header-menu{
    list-style: none;
    display: flex;
    gap: 2rem;
    color: white;
    font-size: 25px;
    font-weight: 500;
    color: rgb(100, 100, 254);
    padding-right: 50px;
}

.header-menu > li:hover{
    cursor: pointer;
    color: var(--orange);
}

@media screen and (max-width: 768px){
    
    .header-menu{
        font-size: normal;
        background-color: var(--appColor);
        padding: 2rem;
    }
}